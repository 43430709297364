.console__title{
    margin-bottom: 50px;
}

.console__create{
    margin-bottom: 5px;
}

.console__link{
    margin-bottom: 50px;
}


.console__edit{
    margin-bottom: 15px;
}

.console__header{
    display: flex;
    justify-content: space-between;
}

.console__logout{
    align-self: flex-start;
}