@font-face {
    font-family: cinzel;
    src: url('fonts/CinzelDecorative-Regular.ttf');
    font-weight: normal;
}
@font-face {
    font-family: cinzel;
    src: url('fonts/CinzelDecorative-Bold.ttf');
    font-weight: bold;
}

@font-face {
    font-family: lato;
    src: url('fonts/Lato-Regular.ttf');
    font-weight: normal;
}

@font-face {
    font-family: lato;
    src: url('fonts/Lato-Bold.ttf');
    font-weight: bold;
}


body{
    margin: 0;
}

*, *::after, *::before{
    box-sizing: border-box;
}

html{
    font-size: 16px;
    background-color: #d1d1d1;
}

.menu-open{
    overflow: hidden;
    height: 100vh;
    position: relative;
}


@media(max-width: 1250px){
    html{
        font-size: 15px;
    }
}

@media(max-width: 1000px){
    html{
        font-size: 14px;
    }
}

@media(max-width: 850px){
    html{
        font-size: 13px;
    }
}

@media(max-width: 550px){
    html{
        font-size: 12px;
    }
}

.text{
    font-size: 1rem;
    font-family: lato, sans-serif;
    line-height: 1.4;
}

.text_light{
    color:#fff;
}

.text_lg{
    font-size: 1.2rem;
}

.text_sm{
    font-size: 0.8rem;
}

.title{
    font-weight: 700;
    font-size: 3rem;
    font-family: cinzel, sans-serif;
    margin: 0;
}

.title_light{
    color: #fff;
}

.title_md{
    font-size: 1.5rem;
}


.title_sm{
    font-size: 1.2rem;
}

.title_xs{
    font-size: 1rem;
}


.section{
    padding-top: 75px;
    padding-bottom: 50px;
    position: relative;
}

.section_post{
    background: linear-gradient(180deg, #fff, #B1D3F7 75%);
}

.section_sm{
    padding-top: 25px;
}

.section:nth-last-child(2){
    padding-bottom: 100px;
}

.section_gradient{
    background: linear-gradient(180deg, #fff, #B1D3F7 75px);
}

.section_blue{
    background-color: #B1D3F7;
}

.no-overflow{
    overflow: hidden;
}

@media(max-width: 1000px){
    .section{
        padding-top: 65px;
        padding-bottom: 45px;
    }

    .section_sm{
        padding-top: 25px;
    }

    .section:nth-last-child(2){
        padding-bottom: 90px;
    }
    

    .section_gradient{
        background: linear-gradient(180deg, #fff, #B1D3F7 65px);
    }
}

@media(max-width: 850px){
    .section{
        padding-top: 55px;
        padding-bottom: 40px;
    }

    .section:nth-last-child(2){
        padding-bottom: 80px;
    }
    
    .section_sm{
        padding-top: 20px;
    }

    .section_gradient{
        background: linear-gradient(180deg, #fff, #B1D3F7 55px);
    }
}

@media(max-width: 700px){
    .section{
        padding-top: 45px;
        padding-bottom: 35px;
    }

    .section_sm{
        padding-top: 20px;
    }

    .section:nth-last-child(2){
        padding-bottom: 70px;
    }    

    .section_gradient{
        background: linear-gradient(180deg, #fff, #B1D3F7 45px);
    }
}

@media(max-width: 550px){
    .section{
        padding-top: 35px;
        padding-bottom: 30px;
    }

    .section_sm{
        padding-top: 20px;
    }

    .section:nth-last-child(2){
        padding-bottom: 60px;
    }    

    .section_gradient{
        background: linear-gradient(180deg, #fff, #B1D3F7 35px);
    }
}

@media(max-width: 450px){
    .section{
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .section_sm{
        padding-top: 15px;
    }

    .section:nth-last-child(2){
        padding-bottom: 50px;
    }    

    .section_gradient{
        background: linear-gradient(180deg, #fff, #B1D3F7 25px);
    }
}

.section_login{
    background: rgba(0,0,0,0.8);
}

.section__content{
    padding: 0 50px;
    max-width: 1250px;
    margin: 0 auto;
}

.section__content_sm{
    max-width: 850px;
    margin: 0 auto;
}

@media(max-width: 1000px){
    .section__content{
        padding: 0 45px;
    }
}

@media(max-width: 850px){
    .section__content{
        padding: 0 40px;
    }
}

@media(max-width: 700px){
    .section__content{
        padding: 0 35px;
    }
}

@media(max-width: 550px){
    .section__content{
        padding: 0 25px;
    }
}

@media(max-width: 450px){
    .section__content{
        padding: 0 20px;
    }
}



p{
    margin: 0;
}

img{
    max-width: 100%;
    height: auto;
    display: block;
}

.page{
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

.page_100vh{
    height: 100vh;
}

.page_overflow{
    overflow: visible;
}

@media(min-width: 2100px){
    .page{
        max-width: 2000px;
        margin: 0 auto;
        box-shadow: 2px 2px 25px rgba(0,0,0,0.8);
    }
}


.button{
    color: #000;
    background-color: transparent;
    display: inline-block;
    padding: 0.5em 1em;
    border: 2px solid #B1D3F7;
    text-decoration: none;
    font-size: 0.8rem;
    font-family: cinzel, sans-serif;
    transition: 0.4s;
    font-weight: 700;
    cursor: pointer;
}

.button:hover,
.button:focus,
.button:active{
    background-color: #B1D3F7;
}

.button_light{
    color:#fff;
}

.button_alt{
    border-color: #FFF05A;
}

.button_alt:hover,
.button_alt:active,
.button_alt:focus{
    background-color: #FFF05A;
}

.button:visited{
    color: #000;
}

.button:disabled:hover,
.button:disabled:active,
.button:disabled:focus{
    background-color: #fff;
    cursor: wait;
}

input[type="text"] {
    display: block;
    outline: none;
    padding: 4px 3px;
    width: 100%;
    border: 1px solid #000;
}

input[type="checkbox"]{
    width: auto;
}

textarea {
    border: 1px solid #000;
}