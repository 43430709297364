.next{

}

.next__title{
    text-align: center;
    margin-bottom: 0.5em;
}

.next__text{
    text-align: center;
}