.supportCTA{
    display: flex;
    background-color: #fff;
    box-shadow: 2px 2px 15px rgba(0,0,0,0.2);
    border-radius: 10px;
    overflow: hidden;
}

.supportCTA__content{
    width: 60%;
    padding: 4rem;
}

.supportCTA__image{
    width: 40%;
    background-image: url("../images/png_sunset.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.supportCTA__title{
    margin-bottom: 0.75em;
}

@media(max-width: 600px){
    .supportCTA__content{
        width: 50%;
        padding: 3rem;
    }

    .supportCTA__image{
        width: 50%;
    }
}

@media(max-width: 450px){
    .supportCTA{
        flex-wrap: wrap-reverse;
    }

    .supportCTA__content{
        width: 100%;
        padding: 1.5rem;
    }

    .supportCTA__image{
        padding-top: 35%;
        width: 100%;
    }
}