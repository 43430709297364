.contact-text{
    text-align: center;
    margin-bottom: 3rem;
}

.contact-form{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.form-label{
    display: block;
}

.form-input{
    display: block;
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: 0;
    margin-bottom: 1.5rem;
}

.form-textarea{
    display: block;
    width: 100%;
    max-width: 500px;
    margin-bottom: 1.5rem;
    outline: none;
}


.form-submit{
    width: auto;
}

.form-success{
    padding-block: 50px;
    text-align: center;
}