.memory{
    height: 100%;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 25px;
    opacity: 0;
    transition: 0.6s;
    background-color: #000;
}

.memory__round{
    margin-bottom: 25px;
}

.memory__guess{
    width: 100%;
}

.memory__buttons{
    display: flex;
    justify-content: space-between;
    margin-right: -25px;
}

.memory__button{
    width: 100%;
    padding-top: 100%;
    border: none;

}
.memory__button-wrapper{
    width: 25%;
    padding-right: 25px;
}

.memory__guess-indicators{
    margin-bottom: 10%;
    display: flex;
    margin-right: -15px;
    transition: 0.3s opacity;
}

@media (max-width: 500px){
    .memory__button{
        width: 100%;
        padding-top:100%;
    }
    .memory__button-wrapper{
        width: 50%;
        padding-top: 0;
        padding-right: 15px;
        padding-bottom: 15px;
    }
    .memory__buttons{
        flex-wrap: wrap;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-top: 25px;
    }
}

.memory__guess-indicator{
    width: 25px;
    height: 25px;
    margin-right: 15px;
    background-color: #fff;
    transition: 0.4s;
    border-radius: 100%;
}

.memory-start-wrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.memory-start{
    width: 70%;
    max-width: 300px;
    padding-top: 25px;
    padding-bottom: 25px; 
    border-radius: 25px;
    border: none;
    margin: 0 auto 15px auto;
    background-color: #B1D3F7;
    font-family: cinzel, sans-serif;
    transition: 0.4s;
}

.memory-help{
    width: 70%;
    max-width: 300px;
    padding-top: 25px;
    padding-bottom: 25px; 
    border-radius: 25px;
    border: none;
    margin: 0 auto;
    background-color: #B1D3F7;
    font-family: cinzel, sans-serif;
    transition: 0.4s;
}

.memory-highscore{
    width: 70%;
    max-width: 300px;
    padding-top: 25px;
    padding-bottom: 25px; 
    border-radius: 25px;
    border: none;
    margin: 0 auto 15px auto;
    background-color: #B1D3F7;
    font-family: cinzel, sans-serif;
    transition: 0.4s;
}

.memory-start:hover, .memory-help:hover, .memory-highscore:hover{
    cursor:pointer;
    background-color: #93a6c7;
}

.memory-title{
    display: block;
    text-align: center;
}

.memory-start-line{
    width: 150px;
    height: 2px;
    background-color:#a2a2a2; 
    align-self: center;
    margin-bottom: 50px;
    margin-top: 50px;
}


@media(max-width: 700px){
    .memory-title{
        margin-top: 50px;
    }
    .memory-start-line{
        margin-top: 40px;
        margin-bottom: 40px;
        width: 100px;
    }
}

.rules, .highscores{
    display: none;
    padding: 15px 25px;
    background-color: #000;
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.rules__content, .highscores__content{
    max-width: 300px;
    margin: 0 auto;
    padding-top: 25px;
}

.rules_title, .highscore__title{
    margin-bottom: 10px;
}

.rules_text{
    margin-bottom: 25px;
}

.new-highscore{

    position:absolute;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 10;
}

.new-highscore_title{
    text-align: center;
    margin-top: 25px;
    margin-bottom: 15px;
}

.highscores__content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.highscore__item{
    text-align: center;
}

.highscore_title{
    text-align: center;
    margin-bottom: 15px;
}

.highscore__textfield{
    max-width: 300px;
    margin-bottom: 15px;
}

.highscore__submit{
    margin: 0 auto;
}

.new-highscore{
    display: none;
    flex-direction: column;
    align-items: center;
}