.updatesCTA{
    display: flex;
} 

.updatesCTA__post{
    width: 50%;
    z-index: 2;
}

.updatesCTA__content{
    position: relative;
    width: 50%;
    align-self: center;
    z-index: 1;
}

.updatesCTA__content::before{
    content: '';
    position: absolute;
    top: -15px;
    left: -100%;
    right: -15px;
    bottom: -15px;
    background-color: #fff;
    z-index: -1;
    border-radius: 10px;
}

.updatesCTA__title{
    margin-bottom: 0.5em;
}

.updatesCTA__text{
    margin-bottom: 0.4em;
}

.updatesCTA-title{
    margin-bottom: 0.75em;
}

@media(max-width: 650px){
    .updatesCTA__content{
        margin-left: 25px;
    }
}

@media(max-width: 400px){
    .updatesCTA{
        flex-wrap: wrap;
        justify-content: center;
    }

    .updatesCTA-title{
        width: 75%;
        margin-inline: auto;
    }

    .updatesCTA__post{
        width: 75%;
    }

    .updatesCTA__content{
        width: 100%;
    }

    .updatesCTA__content{
        margin-left: 0px;
        padding: 25px;
        padding-top: 50px;
        margin-top: -55px;
    }

    .updatesCTA__content::before{
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

    }
}